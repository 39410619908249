import Vue from 'vue'
import VueRouter from 'vue-router'
import Front from "@/views/Front.vue";
import Story from "@/views/Story.vue";
import Videos from "@/views/Videos.vue";
import Video from "@/views/Video.vue";
import Subs from "@/views/Subs.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Frontpage",
    component: Front
  },
  {
    path: "/story/:id",
    name: "Storypage",
    component: Story
  },
  {
    //path: "/videos/:page",
    path: "/videos",
    name: "Videos",
    component: Videos
  },
  {
    path: "/video/:id",
    name: "Video",
    component: Video
  },
  {
    path: "/subtitles",
    name: "Subs",
    component: Subs
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
